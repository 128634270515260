// 
import React from 'react';
const WordPressEmbed = () => {
    return (
        <div>
            <iframe
                title="WordPress Feed"
                src="https://albumlistenings.blogspot.com/"
                style={{ border: 'none' }} // This line removes the border
                width="100%"
                height="1200px"
                scrolling="yes"
            ></iframe>
        </div>
    );
};

export default WordPressEmbed;
//"https://marktension.home.blog/